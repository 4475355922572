<template>
  <a-card :bordered="false">
    <!-- 查询区域 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @keyup.enter.native="searchQuery">
        <a-row :gutter="24">
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="任务标题">
              <a-input placeholder="请输入任务标题" v-model="queryParam.title"></a-input>
            </a-form-item>
          </a-col>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <a-form-item label="发布状态">
              <j-dict-select-tag placeholder="请选择发布状态" v-model="queryParam.isPublish" dictCode="send_status" />
            </a-form-item>
          </a-col>
          <template v-if="toggleSearchStatus">
            <a-col :xl="6" :lg="7" :md="8" :sm="24">
              <a-form-item label="状态">
                <j-dict-select-tag placeholder="请选择状态" v-model="queryParam.isEnable" dictCode="status" />
              </a-form-item>
            </a-col>
          </template>
          <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left; overflow: hidden" class="table-page-search-submitButtons">
              <a-button type="primary" @click="searchQuery" icon="search">查询</a-button>
              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">重置</a-button>
              <a @click="handleToggleSearch" style="margin-left: 8px">
                {{ toggleSearchStatus ? '收起' : '展开' }}
                <a-icon :type="toggleSearchStatus ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <!-- 查询区域-END -->

    <!-- 操作按钮区域 -->
    <div class="table-operator">
      <a-button @click="handleAdd" type="primary" icon="plus">新增</a-button>
      <!--      <a-button type="primary" icon="download" @click="handleExportXls('任务表')">导出</a-button>
      <a-upload name="file" :showUploadList="false" :multiple="false" :headers="tokenHeader" :action="importExcelUrl" @change="handleImportExcel">
        <a-button type="primary" icon="import">导入</a-button>
      </a-upload>
      &lt;!&ndash; 高级查询区域 &ndash;&gt;
      <j-super-query :fieldList="superFieldList" ref="superQueryModal" @handleSuperQuery="handleSuperQuery"></j-super-query>
      <a-dropdown v-if="selectedRowKeys.length > 0">
        <a-menu slot="overlay">
          <a-menu-item key="1" @click="batchDel"><a-icon type="delete"/>删除</a-menu-item>
        </a-menu>
        <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /></a-button>
      </a-dropdown>-->
    </div>

    <!-- table区域-begin -->
    <div>
      <div class="ant-alert ant-alert-info" style="margin-bottom: 16px">
        <i class="anticon anticon-info-circle ant-alert-icon"></i> 已选择
        <a style="font-weight: 600">{{ selectedRowKeys.length }}</a
        >项
        <a style="margin-left: 24px" @click="onClearSelected">清空</a>
      </div>

      <a-table
        ref="table"
        size="middle"
        :scroll="{ x: true }"
        bordered
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :pagination="ipagination"
        :loading="loading"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        class="j-table-force-nowrap"
        @change="handleTableChange"
      >
        <template slot="htmlSlot" slot-scope="text">
          <div v-html="text"></div>
        </template>
        <template slot="imgSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px; font-style: italic">无图片</span>
          <img
            v-else
            :src="getImgView(text)"
            height="25px"
            alt=""
            style="max-width: 80px; font-size: 12px; font-style: italic"
          />
        </template>
        <template slot="fileSlot" slot-scope="text">
          <span v-if="!text" style="font-size: 12px; font-style: italic">无文件</span>
          <a-button v-else :ghost="true" type="primary" icon="download" size="small" @click="downloadFile(text)">
            下载
          </a-button>
        </template>

        <span slot="action" slot-scope="text, record">
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="viewReport(record)" v-if="record.isPublish == 1">查看报告</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定发布吗?"
            v-if="record.isPublish != 1"
            @confirm="() => handlePublish(record.id, record.isPublish)"
          >
            <a>发布</a>
          </a-popconfirm>
          <a-popconfirm
            title="确定撤销吗?"
            v-if="record.isPublish == 1"
            @confirm="() => handlePublish(record.id, record.isPublish)"
          >
            <a>撤销</a>
          </a-popconfirm>
          <a-divider type="vertical" />
          <a-dropdown>
            <a class="ant-dropdown-link">更多 <a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="handleDetail(record)">详情</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="确定删除吗?" @confirm="() => handleDelete(record.id)">
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </span>
      </a-table>
    </div>

  </a-card>
</template>

<script>
import '@/assets/less/TableExpand.less'
import { mixinDevice } from '@/utils/mixin'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { filterMultiDictText } from '@/components/dict/JDictSelectUtil'
import { getAction,putAction} from '@/api/manage'
export default {
  mixins: [JeecgListMixin, mixinDevice],
  components: {
  },
  data() {
    return {
      description: '任务表管理页面',
      // 表头
      columns: [
        /* {
            title: '#',
            dataIndex: '',
            key:'rowIndex',
            width:60,
            align:"center",
            customRender:function (t,r,index) {
              return parseInt(index)+1;
            }
          }, */
        {
          title: '任务标题',
          align: 'center',
          dataIndex: 'title',
        },
        {
          title: '任务分类',
          align: 'center',
          dataIndex: 'categoryId_dictText',
        },
        /* {
            title:'任务描述',
            align:"center",
            dataIndex: 'description'
          }, */
        {
          title: '开始时间',
          align: 'center',
          dataIndex: 'startTime',
        },
        {
          title: '结束时间',
          align: 'center',
          dataIndex: 'endTime',
        },
        {
          title: '发布状态',
          align: 'center',
          dataIndex: 'isPublish_dictText',
        },
        {
          title: '发布时间',
          align: 'center',
          dataIndex: 'publishTime',
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'isEnable_dictText',
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'createTime',
          customRender: function (text) {
            return !text ? '' : text.length > 10 ? text.substr(0, 10) : text
          },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          fixed: 'right',
          width: 147,
          scopedSlots: { customRender: 'action' },
        },
      ],
      url: {
        list: '/ylpp/task/list',
        delete: '/ylpp/task/delete',
        deleteBatch: '/ylpp/task/deleteBatch',
        exportXlsUrl: '/ylpp/task/exportXls',
        importExcelUrl: 'ylpp/task/importExcel',
        publishTask: 'ylpp/task/publish/',
        cancelTask: 'ylpp/task/cancel/',
      },
      dictOptions: {},
      superFieldList: [],
    }
  },
  created() {
    this.getSuperFieldList()
  },
  computed: {
    importExcelUrl: function () {
      return `${window._CONFIG['domianURL']}/${this.url.importExcelUrl}`
    },
  },
  methods: {
    add () {
        this.edit(this.modelDefault);
      },
      edit (record) {
        console.log(111)
        console.log(record)
        console.log(111)
        this.model = Object.assign({}, record);
        this.visible = true;
        //this.viewNews(record)
      },
      viewNews(record){
        getAction(this.url.newsView,{id:record.id}).then((res)=>{
             record.classes = res.result.classes;
             record.asstes = res.result.asstes;
             record.resources = res.result.resources;
             this.model = Object.assign({}, record);
          })
      },
    initDictConfig() {},
    getSuperFieldList() {
      let fieldList = []
      fieldList.push({ type: 'string', value: 'title', text: '任务标题', dictCode: '' })
      fieldList.push({
        type: 'int',
        value: 'categoryId',
        text: '任务分类',
        dictCode: 'task_category where is_enable=1,name,id',
      })
      fieldList.push({ type: 'string', value: 'description', text: '任务描述', dictCode: '' })
      fieldList.push({ type: 'datetime', value: 'startTime', text: '开始时间' })
      fieldList.push({ type: 'datetime', value: 'endTime', text: '结束时间' })
      fieldList.push({ type: 'int', value: 'isPublish', text: '发布状态', dictCode: 'send_status' })
      fieldList.push({ type: 'datetime', value: 'publishTime', text: '发布时间' })
      fieldList.push({ type: 'int', value: 'isEnable', text: '状态', dictCode: 'status' })
      fieldList.push({ type: 'date', value: 'createTime', text: '创建时间' })
      this.superFieldList = fieldList
    },handlePublish(id, isFlag) {
      let url1 = isFlag == 1 ? this.url.cancelTask : this.url.publishTask;
      putAction(`${url1}${id}`, {}).then((res) => {
        if (res.success) {
          this.loadData()
          //this.syncHeadNotic(record.anntId)
        }
      })
    },viewReport(){

    }
  },
}
</script>
<style scoped>
@import '~@assets/less/common.less';
</style>