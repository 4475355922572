var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-card", { attrs: { bordered: false } }, [
    _c(
      "div",
      { staticClass: "table-page-search-wrapper" },
      [
        _c(
          "a-form",
          {
            attrs: { layout: "inline" },
            nativeOn: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchQuery($event)
              }
            }
          },
          [
            _c(
              "a-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "a-col",
                  { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                  [
                    _c(
                      "a-form-item",
                      { attrs: { label: "任务标题" } },
                      [
                        _c("a-input", {
                          attrs: { placeholder: "请输入任务标题" },
                          model: {
                            value: _vm.queryParam.title,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParam, "title", $$v)
                            },
                            expression: "queryParam.title"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                  [
                    _c(
                      "a-form-item",
                      { attrs: { label: "发布状态" } },
                      [
                        _c("j-dict-select-tag", {
                          attrs: {
                            placeholder: "请选择发布状态",
                            dictCode: "send_status"
                          },
                          model: {
                            value: _vm.queryParam.isPublish,
                            callback: function($$v) {
                              _vm.$set(_vm.queryParam, "isPublish", $$v)
                            },
                            expression: "queryParam.isPublish"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.toggleSearchStatus
                  ? [
                      _c(
                        "a-col",
                        { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "状态" } },
                            [
                              _c("j-dict-select-tag", {
                                attrs: {
                                  placeholder: "请选择状态",
                                  dictCode: "status"
                                },
                                model: {
                                  value: _vm.queryParam.isEnable,
                                  callback: function($$v) {
                                    _vm.$set(_vm.queryParam, "isEnable", $$v)
                                  },
                                  expression: "queryParam.isEnable"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _c("a-col", { attrs: { xl: 6, lg: 7, md: 8, sm: 24 } }, [
                  _c(
                    "span",
                    {
                      staticClass: "table-page-search-submitButtons",
                      staticStyle: { float: "left", overflow: "hidden" }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "search" },
                          on: { click: _vm.searchQuery }
                        },
                        [_vm._v("查询")]
                      ),
                      _c(
                        "a-button",
                        {
                          staticStyle: { "margin-left": "8px" },
                          attrs: { type: "primary", icon: "reload" },
                          on: { click: _vm.searchReset }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "a",
                        {
                          staticStyle: { "margin-left": "8px" },
                          on: { click: _vm.handleToggleSearch }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.toggleSearchStatus ? "收起" : "展开") +
                              "\n              "
                          ),
                          _c("a-icon", {
                            attrs: {
                              type: _vm.toggleSearchStatus ? "up" : "down"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ],
              2
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-operator" },
      [
        _c(
          "a-button",
          {
            attrs: { type: "primary", icon: "plus" },
            on: { click: _vm.handleAdd }
          },
          [_vm._v("新增")]
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "div",
          {
            staticClass: "ant-alert ant-alert-info",
            staticStyle: { "margin-bottom": "16px" }
          },
          [
            _c("i", {
              staticClass: "anticon anticon-info-circle ant-alert-icon"
            }),
            _vm._v(" 已选择\n      "),
            _c("a", { staticStyle: { "font-weight": "600" } }, [
              _vm._v(_vm._s(_vm.selectedRowKeys.length))
            ]),
            _vm._v("项\n      "),
            _c(
              "a",
              {
                staticStyle: { "margin-left": "24px" },
                on: { click: _vm.onClearSelected }
              },
              [_vm._v("清空")]
            )
          ]
        ),
        _c("a-table", {
          ref: "table",
          staticClass: "j-table-force-nowrap",
          attrs: {
            size: "middle",
            scroll: { x: true },
            bordered: "",
            rowKey: "id",
            columns: _vm.columns,
            dataSource: _vm.dataSource,
            pagination: _vm.ipagination,
            loading: _vm.loading,
            rowSelection: {
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange
            }
          },
          on: { change: _vm.handleTableChange },
          scopedSlots: _vm._u([
            {
              key: "htmlSlot",
              fn: function(text) {
                return [_c("div", { domProps: { innerHTML: _vm._s(text) } })]
              }
            },
            {
              key: "imgSlot",
              fn: function(text) {
                return [
                  !text
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "font-style": "italic"
                          }
                        },
                        [_vm._v("无图片")]
                      )
                    : _c("img", {
                        staticStyle: {
                          "max-width": "80px",
                          "font-size": "12px",
                          "font-style": "italic"
                        },
                        attrs: {
                          src: _vm.getImgView(text),
                          height: "25px",
                          alt: ""
                        }
                      })
                ]
              }
            },
            {
              key: "fileSlot",
              fn: function(text) {
                return [
                  !text
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "12px",
                            "font-style": "italic"
                          }
                        },
                        [_vm._v("无文件")]
                      )
                    : _c(
                        "a-button",
                        {
                          attrs: {
                            ghost: true,
                            type: "primary",
                            icon: "download",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.downloadFile(text)
                            }
                          }
                        },
                        [_vm._v("\n          下载\n        ")]
                      )
                ]
              }
            },
            {
              key: "action",
              fn: function(text, record) {
                return _c(
                  "span",
                  {},
                  [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleEdit(record)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    record.isPublish == 1
                      ? _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.viewReport(record)
                              }
                            }
                          },
                          [_vm._v("查看报告")]
                        )
                      : _vm._e(),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    record.isPublish != 1
                      ? _c(
                          "a-popconfirm",
                          {
                            attrs: { title: "确定发布吗?" },
                            on: {
                              confirm: function() {
                                return _vm.handlePublish(
                                  record.id,
                                  record.isPublish
                                )
                              }
                            }
                          },
                          [_c("a", [_vm._v("发布")])]
                        )
                      : _vm._e(),
                    record.isPublish == 1
                      ? _c(
                          "a-popconfirm",
                          {
                            attrs: { title: "确定撤销吗?" },
                            on: {
                              confirm: function() {
                                return _vm.handlePublish(
                                  record.id,
                                  record.isPublish
                                )
                              }
                            }
                          },
                          [_c("a", [_vm._v("撤销")])]
                        )
                      : _vm._e(),
                    _c("a-divider", { attrs: { type: "vertical" } }),
                    _c(
                      "a-dropdown",
                      [
                        _c(
                          "a",
                          { staticClass: "ant-dropdown-link" },
                          [
                            _vm._v("更多 "),
                            _c("a-icon", { attrs: { type: "down" } })
                          ],
                          1
                        ),
                        _c(
                          "a-menu",
                          { attrs: { slot: "overlay" }, slot: "overlay" },
                          [
                            _c("a-menu-item", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handleDetail(record)
                                    }
                                  }
                                },
                                [_vm._v("详情")]
                              )
                            ]),
                            _c(
                              "a-menu-item",
                              [
                                _c(
                                  "a-popconfirm",
                                  {
                                    attrs: { title: "确定删除吗?" },
                                    on: {
                                      confirm: function() {
                                        return _vm.handleDelete(record.id)
                                      }
                                    }
                                  },
                                  [_c("a", [_vm._v("删除")])]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }