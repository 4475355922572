var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-button",
        { attrs: { type: "primary" }, on: { click: _vm.handleTableCheck } },
        [_vm._v("表单验证")]
      ),
      _c("span", { staticStyle: { "padding-left": "8px" } }),
      _c(
        "a-tooltip",
        {
          attrs: {
            placement: "top",
            title: "获取值，忽略表单验证",
            autoAdjustOverflow: true
          }
        },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleTableGet } },
            [_vm._v("获取值")]
          )
        ],
        1
      ),
      _c("span", { staticStyle: { "padding-left": "8px" } }),
      _c(
        "a-tooltip",
        {
          attrs: {
            placement: "top",
            title: "模拟加载1000条数据",
            autoAdjustOverflow: true
          }
        },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleTableSet } },
            [_vm._v("设置值")]
          )
        ],
        1
      ),
      _c("j-editable-table", {
        ref: "editableTable",
        staticStyle: { "margin-top": "8px" },
        attrs: {
          loading: _vm.loading,
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          rowNumber: true,
          rowSelection: true,
          actionButton: true,
          dragSort: true
        },
        on: { selectRowChange: _vm.handleSelectRowChange },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function(props) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.handleDelete(props)
                      }
                    }
                  },
                  [_vm._v("删除")]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }